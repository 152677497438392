import $ from "jquery";
import L from 'leaflet';

export default class RiverCheckMap {

  constructor() {
    this.selector_rivercheckmap_container = ".rivercheckmap-container";
    this.selector_content_wrapper = ".content-wrapper";
    this.selector_content_container = ".content";
    this.selector_fundorte_container = ".fundorte";
    this.is_default_content_shown = true;
    this.selected_fundort_id = 0;
    this.remove_callback_timeout_id = null;
    this.mouseleave_callback_timeout_id = null;

    this.initialized = false;

    /* This code is needed to properly load the images in the Leaflet CSS */
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }

  init() {
    if (this.initialized == true) return false;

    this.$rivercheckmap_containers = $(this.selector_rivercheckmap_container);
    this.$fundorte_container = $(this.selector_fundorte_container);

    this.maps = [];
    var that = this;
    that.$rivercheckmap_containers.each(function () {
      that.initialized = true;
      const $rivercheckmap_container = $(this);
      var map_elem = $(".map", $(this));
      that.showOrHideDefaultContent($rivercheckmap_container, true);

      var map = that.buildMap(map_elem[0]);
      $(this).data('map', map);
      that.addEventHandler($rivercheckmap_container);

      var markers = [];
      var json_data = $(this).data('json');
      var display_type = $(this).data('display');
      var show_samples = $(this).data('show-samples');
      const resource_path = $(this).data('resource-base');
      var defaultIcon = new L.Icon.Default();
      var shadowUrl = resource_path + "will-be-removed/" + defaultIcon.options.shadowUrl.default;

      $.each(json_data, function (key, entry) {
        if (entry.longitude == null || entry.latitude == null)
          return false;

        var marker_options = {
          icon: L.icon({
            iconUrl: entry.icon,
            iconSize: [26, 40], // size of the icon
            shadowUrl: shadowUrl, // Referenzieren des Standard-Schattens
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [13, 40], // point of the icon which will correspond to marker's location
            shadowAnchor: [13, 62],  // the same for the shadow
            popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
          })
        };

        var marker = L.marker([entry.latitude, entry.longitude], marker_options);
        marker.id = entry.id;
        that.configureMarker(marker, entry, display_type, $rivercheckmap_container, show_samples);
        marker.addTo(map);
        markers.push(marker);
      });

      var group = new L.featureGroup(markers);
      $(this).data("markerGroup", group);
      $(this).data("markers", markers);

      that.maps.push(map);
      var $map_container = $(".map", $rivercheckmap_container);
      setTimeout(function () {
        that.mapContainerTransitionEndedCB($rivercheckmap_container)
      }, 200);
      $map_container.show();

    });
  }

  showOrHideDefaultContent($rivercheckmap_container, show) {
    console.log("showOrHideDefaultContent");
    if (!show) {
      $rivercheckmap_container.removeClass('default-content');
      $rivercheckmap_container.addClass('fundorte-content');
      $('.fundorte', $rivercheckmap_container).show();
      this.is_default_content_shown = false;
    } else {
      $rivercheckmap_container.addClass('default-content');
      $rivercheckmap_container.removeClass('fundorte-content');
      $('.fundorte', $rivercheckmap_container).hide();
      this.is_default_content_shown = true;
      this.selected_fundort_id = 0;
    }
  }

  mapContainerTransitionEndedCB($rivercheckmap_container) {
    var leaflet_map = $rivercheckmap_container.data('map');
    var group = $rivercheckmap_container.data('markerGroup');
    leaflet_map.invalidateSize();
    if (this.selected_fundort_id === 0) {
      leaflet_map.fitBounds(group.getBounds(), { padding: [50, 50] });
    }

  }

  removeCB(that, $rivercheckmap_container) {
    that.showOrHideDefaultContent($rivercheckmap_container, true);
  }

  configureMarker(leaflet_marker, data, display_type, $rivercheckmap_container, show_samples = false) {
    var markup = '';
    var that = this;
    if (display_type == 'map') {
      markup = '<b>' + data.title + '</b>';

      if (data.probe != null && show_samples == true) {
        markup += '<br/>' + 'Probe vom ' + data.probe.date + '<br/>';
        markup += '<table>';
        markup += '<tbody>';
        // laufe über alle Parameter und gib sie aus
        $.each(data.probe.messwerte, function (key, entry) {
          markup += '<tr><td><b>' + entry.parameter + '</b></td><td>' + entry.messwert + ' ' + entry.einheit + '</td></tr>';
        });

        if (data.probe.url != null) {
          //markup += '<a href="' + data.probe.url + '" >mehr...</a>';
        };
        markup += '</tbody>';

      } else {
        markup += '<br/> Keine Probe vorhanden <br/>';
      }
    } else if (display_type == 'all' || display_type == 'single') {
      markup = '<small>' + data.category_name + '</small><br/><b>' + data.title + '</b>';
    }
    leaflet_marker.bindPopup(markup, { closeButton: false });

    $('.fundort-links li[data-id="' + leaflet_marker.id + '"]', $rivercheckmap_container).data('leaflet_marker', leaflet_marker);

    if (display_type == 'all') {
      var popup = leaflet_marker.getPopup();
      popup.on('remove', function (ev) {
        that.remove_callback_timeout_id = setTimeout(that.removeCB, 100, that, $rivercheckmap_container);
      });

      leaflet_marker.on('click', function (e) {
        that.$fundorte_container.children().hide(false);
        clearTimeout(that.remove_callback_timeout_id);

        if (that.is_default_content_shown) {
          that.showOrHideDefaultContent($rivercheckmap_container, false);
        }
        $('.fundort-links li').removeClass('active');
        if (that.selected_fundort_id === leaflet_marker.id) {
          that.showOrHideDefaultContent($rivercheckmap_container, true);
          return true;
        }

        $('.fundort-links li[data-id="' + leaflet_marker.id + '"]').addClass('active');

        that.selected_fundort_id = leaflet_marker.id;
        var fundort_sel = "#fundort_" + leaflet_marker.id;
        var $fundort_content = $(fundort_sel);
        $fundort_content.fadeIn(500);
        var leaflet_map = $rivercheckmap_container.data('map');
        leaflet_map.invalidateSize();
        leaflet_map.setView(leaflet_marker.getLatLng());
      });

    }
  }

  buildMap($html_elem) {
    var map = L.map($html_elem, { scrollWheelZoom: false });
    map.setView([51.505, -0.09], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    return map;
  }

  addEventHandler($rivercheckmap_container) {
    var that = this;
    var leaflet_map = $($rivercheckmap_container).data('map');
    var tour_content_wrapper = $(that.selector_content_wrapper, $rivercheckmap_container);
    tour_content_wrapper.on("transitionend", function () {
      that.mapContainerTransitionEndedCB($rivercheckmap_container);
    });

    var $back_link_wrapper = $('.back-link-wrapper', $rivercheckmap_container);
    $back_link_wrapper.on('click', function () {
      that.showOrHideDefaultContent($rivercheckmap_container, true);
      return true;
    });

    var $fundort_links = $('.fundort-links li', $rivercheckmap_container);
    $fundort_links.on('click', function () {
      const leaflet_marker = $(this).data('leaflet_marker');
      $fundort_links.removeClass('active');
      $(this).addClass('active');
      that.handle_mouseleave = false;
      leaflet_marker.closePopup();
      leaflet_marker.fire('click');
      return true;
    });
    /*
        $fundort_links.on('mouseenter', function () {
          clearTimeout(that.mouseleave_callback_timeout_id);
          const leaflet_marker = $(this).data('leaflet_marker');
          that.handle_mouseleave = true;
          leaflet_map.panTo(leaflet_marker.getLatLng());
          leaflet_marker.openPopup();
          //leaflet_map.setView(leaflet_marker.getLatLng());
          leaflet_map.invalidateSize();
    
          $(this).one("mouseleave", function () {
            if (that.handle_mouseleave == true) {
              that.mouseleave_callback_timeout_id = setTimeout(function () {
                leaflet_map.closePopup();
                that.mapContainerTransitionEndedCB($rivercheckmap_container);
              }, 1000);
            }
          });
    
        });
    */

  }

  mount() {
    var that = this;
    $(function () {
      that.init();
    });
  }
}

window.RiverCheckMap = new RiverCheckMap();
window.RiverCheckMap.mount();